export default {
    app: {
      id: 'leslies-express',
      server: {
        url: 'https://lesliesexpress.ph'
      }
    },
    firebase: {
      apiKey: "AIzaSyDADgTFrfiqoBdggynXub0uxW_AAYPm4oU",
      authDomain: "magis-watch.firebaseapp.com",
      databaseURL: "https://magis-watch.firebaseio.com",
      projectId: "magis-watch",
      storageBucket: "magis-watch.appspot.com",
      messagingSenderId: "129311472715",
      appId: "1:129311472715:web:eb6e28b1b7d19ee86961ce",
      measurementId: "G-42Y1RBHZ96"
    }
}